<template>
    <v-toolbar-title class="ml-0">
        <v-row align="center">
            <v-col
                cols="auto"
                :class="{ 'toolbar-title-width': $vuetify.breakpoint.mdAndUp }"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="pr-0"
                    >
                        <div
                            @click.stop="$parent['$parent'].$emit('update:drawer', !drawer);"
                            v-bind:class="{ open: drawer }"
                            class="nav-ham d-inline-block white-background"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.smAndUp"
                        cols="auto"
                        class="pl-0"
                    >
                            <span
                                class="white--text text-light"
                                style="font-size: 18px;"
                            >
                                {{ $t(currentRouteName) }}
                            </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                :class="{ 'col-auto' : $vuetify.breakpoint.smAndUp, 'col-9' : $vuetify.breakpoint.xsOnly }"
                class="pl-0"
            >
                <v-autocomplete
                    v-model="model"
                    :items="entities"
                    :loading="loading"
                    :search-input.sync="search"
                    :label="$t(`chooseArena.${entities.length > 0 ? 'title' : 'search'}`)"
                    :no-data-text="$t(`chooseArena.${entities.length > 0 ? 'noArenas' : 'typeInTheName'}`)"
                    :dense="$vuetify.breakpoint.xsOnly"
                    :disabled="loading"
                    item-text="name"
                    item-value="id"
                    return-object
                    hide-selected
                    hide-details
                    clearable
                    chips
                    solo
                >
                    <template v-slot:selection="{ attr, on, item, selected }">
                        <v-chip
                            v-bind="attr"
                            v-on="on"
                            :input-value="selected"
                            color="secondary"
                            class="white--text"
                        >
                            <v-icon
                                v-if="$vuetify.breakpoint.smAndUp"
                                left
                            >
                                location_on
                            </v-icon>
                            <span v-text="formatArenaName(item.name)"></span>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                            <v-img
                                :src="item.image"
                                :alt="item.name"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="formatAddress(item.address)"></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col>
                <v-dialog
                    v-model="scanUserDialog"
                    max-width="1000px"
                >
                    <v-card>
                        <v-card-text class="px-0 pb-0">
                            <v-toolbar :color="'primary'" class="elevation-2">
                                <v-toolbar-title class="ml-0">
                                    <span class="font-weight-bold white--text">
                                        {{ $t('cardIdScan.buttonTitle') }}
                                    </span>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn @click="closeScanUserDialog" icon dark>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-container>
                                <template v-if="!showUserDialog">
                                     <v-card class="px-4 pb-4">
                                        <v-text-field
                                            :placeholder="$t('cardIdScan.inputPlaceholder')"
                                            v-model="cardIdScan"
                                            @input="checkQRScan"
                                            ref="hiddenInput"
                                            type="number"
                                            autofocus
                                            loading
                                        ><v-icon>close</v-icon></v-text-field>
                                         <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
                                    </v-card>
                                </template>
                                <template v-else>
                                    <template v-if="loadingUser">
                                        <ProgressCircular color="warning"/>
                                    </template>
                                    <template v-else>
                                        <v-card class="my-4">
                                            <v-simple-table class="do-not-apply-hover" dense>
                                                <thead>
                                                    <tr>
                                                        <th colspan="3" class="text-left" >
                                                            {{ $t('entities.orders.generalInformation') }}
                                                        </th>
                                                        <th class="text-right">
                                                            #{{ foundEntity.id }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.username')}}</td>
                                                        <td class="font-weight-bold"> {{foundEntity.username}}</td>
                                                        <td>{{ $t('generalAttributes.fullName') }}</td>
                                                        <td class="font-weight-bold">{{ foundEntity.identity?.name || '/' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.email') }}</td>
                                                        <td>
                                                            <template v-if="foundEntity.email">
                                                                <CopyTooltip :text="foundEntity.email.address"/>
                                                                <Status
                                                                    v-if="foundEntity.email.id"
                                                                    :status="foundEntity.email.status"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                /
                                                            </template>
                                                        </td>
                                                        <td>{{ $t('entities.users.attributes.rank') }}</td>
                                                        <td class="font-weight-bold">
                                                            <v-chip
                                                                color="primary"
                                                                class="font-weight-bold mr-2"
                                                                text-color="white"
                                                                small
                                                            >
                                                                {{ foundEntity.rank.info.name }}
                                                            </v-chip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-card>
                                        <v-card class="my-4">
                                            <v-simple-table class="do-not-apply-hover" dense>
                                                <thead>
                                                    <tr>
                                                        <th colspan="3" class="text-left" >
                                                            {{ $t('miscellaneous.actions') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td class="py-2">
                                                        <UserActions
                                                            :entity="foundEntity"
                                                            :userSessionInitial="userSession"
                                                            :viewButton=true
                                                            :editButton=true
                                                            :shopButton=true
                                                            :simulateSessionButton=true
                                                            :sessionButton=true
                                                            :deleteButton=false
                                                            @shop-button-clicked="closeScanUserDialog"
                                                            @user-session-action="fetchUserSessions"
                                                        />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-card>
                                    </template>
                                </template>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-btn
                    v-if="showScanButton"
                    v-on="on"
                    class="autocomplete-size d-none d-lg-flex"
                    color="warning"
                    @click="openScanUserDialog"
                >
                    <v-icon class="mr-2">
                        mdi-qrcode-scan
                    </v-icon>
                    {{ $t('cardIdScan.buttonTitle') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-toolbar-title>
</template>

<script>

import Vue from 'vue';
import Arena from '@/models/setup/Arena';

import convertCase from '@/mixins/convert-case';
import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';
import User from "@/models/users/User";
import ProgressCircular from "@/components/ProgressCircular.vue";
import Status from "@/components/Status.vue";
import CopyTooltip from "@/components/CopyTooltip.vue";
import UserActions from "@/components/UserActions.vue";
import Session from "@/models/main/Session";


export default Vue.extend({
    components: {UserActions, CopyTooltip, Status, ProgressCircular},
    props: ['drawer', 'currentRouteName'],

    mixins: [
        convertCase,
        user,
        isRole,
    ],

    data: () => ({
        loading: false,
        entities: [],
        sessions: [],
        model: null,
        search: null,
        debounce: null,
        cardIdScan: '',
        scanUserDialog: false,
        showUserDialog: false,
        loadingUser: false,
        userFound: false,
        userSession: {},
        errorMessage: '',
        foundEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            keycloak_id: null,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        defaultEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            keycloak_id: null,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
    }),

    watch: {
        search() {
            if (this.search) {
                if (this.debounce) {
                    clearTimeout(this.debounce)
                }
                this.debounce = setTimeout(() => {
                    this.fetchEntities()
                }, 250)
            }
        },
        model(val) {
            let arenaName = '';
            if (!val) {
                this.$store.commit('setArena', null);
                this.$store.commit('setSelectedCashRegisterLabel', null);
            } else {
                arenaName = this.titleToKebabCase(val.name);
                if (!this.$store.getters.arena || this.$store.getters.arena.id !== val.id) {
                    const arena = {
                        id: val.id,
                        name: val.name,
                        timezone: val.timezone,
                        cashRegisters: val.cash_registers,
                        paymentMethods: val.payment_methods.map(function(item) {
                            return item.payment_method
                        }),
                        company: {
                            taxes: val.company.taxes,
                        },
                        currency: val.currency,
                        locale: val.locale,
                        coins_per_currency: val.coins_per_currency,
                        automaticallyOpenPrintUponInvoiceCreation: val.invoice_settings.automatically_open_print_upon_invoice_creation,
                    };
                    this.$store.commit('setArena', arena);
                    this.$store.commit('setSelectedCashRegisterLabel', null);
                    this.$store.commit('setCountry', val.address?.country_code.toLowerCase() | 'us');
                    localStorage.setItem('preferred_arena', this.titleToKebabCase(arena.name));
                }
            }
            this.pushToRoute(this.$router.currentRoute.name, arenaName);
        },
        scanUserDialog(val) {
            if (!val) {
                this.closeScanUserDialog();
            }
        },
        '$store.getters.arena'(newVal) {
            if (newVal) {
                console.log("NEW VAL")
                this.showScanButton();
            }
        }
    },

    mounted() {
        const arena = this.$route.params.arena;
        if (arena) {
            this.fetchEntities(this.snakeToTitleCase(arena.replaceAll('-', '_')));
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.focusHiddenInput);
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        document.removeEventListener('focusout', this.handleFocusOut);
    },

    computed: {
        showScanButton() {
            return this.$store.getters.arena && (this.$store.getters.arena.id === 2 || this.$store.getters.arena.id === 21);
        }
    },

    methods: {
        focusHiddenInput() {
            if (this.$refs.hiddenInput) {
                this.$refs.hiddenInput.focus();
            }
        },
        handleVisibilityChange() {
            if (!document.hidden) {
                this.focusHiddenInput();
            }
        },
        handleFocusOut(event) {
            if (this.scanUserDialog && !event.relatedTarget) {
                this.$nextTick(() => {
                    this.focusHiddenInput();
                });
            }
        },
        openScanUserDialog() {
            this.scanUserDialog = true;
            this.$nextTick(() => {
                this.focusHiddenInput();
            });

            document.addEventListener('click', this.focusHiddenInput);
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
            document.addEventListener('focusout', this.handleFocusOut);
        },
        closeScanUserDialog() {
            this.scanUserDialog = false;
            this.showUserDialog = false;
            this.cardIdScan = '';
            this.errorMessage = '';
            this.foundEntity = JSON.parse(JSON.stringify(this.defaultEntity));

            document.addEventListener('click', this.focusHiddenInput);
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
            document.removeEventListener('focusout', this.handleFocusOut);
        },

        checkQRScan() {
            if (this.debounce) {
                clearTimeout(this.debounce);
            }

            this.debounce = setTimeout(() => {

                const cardIdPattern = /^\d{6}$/;
                console.log(this.cardIdScan);
                if (cardIdPattern.test(this.cardIdScan)) {
                    this.fetchUserAndSession(this.cardIdScan);
                } else {
                    console.log(this.cardIdScan);
                    this.errorMessage =  this.$t('cardIdScan.invalidCardFormat');
                }
            }, 500);
        },
        async fetchEntities(arenaName = '') {
            try {
                this.loading = true;
                let search = `name=${this.search || arenaName}`;
                if (!this.isAdmin) {
                    search += `&employee_id=${this.user.id}`;
                }
                const response = await Arena.api.get(`${search}&include_fields=company&include_fields=address&include_fields=cash_registers&include_fields=payment_methods&include_fields=invoice_settings`);
                this.entities.push(...response.data.data.map((ArenaDTO) => {
                    const arena = new Arena(ArenaDTO);
                    if (arena.name.toLowerCase() === arenaName.toLowerCase()) {
                        this.model = arena;
                    }
                    return arena;
                }));
            } catch (e) {
                console.warn('Arenas API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchUserAndSession(cardId) {
            try {
                this.loadingUser = true;
                const response = await User.api.get(`card_id=${cardId}`);
                const data = response.data;

                if (data.content.length === 1) {
                    this.foundEntity = new User(data.content[0]);
                    if (!this.foundEntity.identity) {
                        this.foundEntity.identity = {
                            name: '',
                            birthdate: null,
                            address: '',
                            pidn: '',
                            image: ''
                        }
                    }
                    if (!this.foundEntity.phone) {
                        this.foundEntity.phone = {
                            id: null,
                            country_code: '',
                            national_number: null,
                        }
                    }
                    if (!this.foundEntity.email) {
                        this.foundEntity.email = {
                            id: null,
                            address: '',
                            status: 'UNVERIFIED',
                        }
                    }
                    await this.fetchUserSessions();
                    this.showUserDialog = true;
                } else {
                    this.errorMessage = this.$t('cardIdScan.userNotFound');
                    this.cardIdScan = '';
                    this.showUserDialog = false;
                }
            } catch (e) {
                console.warn('Core API failed.');
                console.log(e);
            } finally {
                this.loadingUser = false;
            }
        },
        pushToRoute(route, arenaName) {
            const params = {};
            if (arenaName.length) {
                params.arena = arenaName;
            }
            if (this.$router.currentRoute.params.arena !== params.arena) {
                this.$router.push({
                    name: route,
                    params: params
                });
            }
        },
        formatArenaName(name) {
            if (this.$vuetify.breakpoint.smAndUp) {
                return name;
            } else if (name.trim().indexOf(' ') !== -1) {
                return name.match(/\b(\w)/g).join("").toUpperCase();
            } else {
                return name.substring(0, 2).toUpperCase();
            }
        },
        formatAddress(address) {
            return address && address.address1 ? `${address.address1}${address.address2 ? `, ${address.address2}` : ''}${address.settlement ? `, ${address.settlement}` : ''}${address.postcode ? `, ${address.postcode}` : ''}` : '';
        },
        async fetchUserSessions() {

            if (!this.$store.getters.arena) {
                return;
            }

            try {
                this.loadingSessions = true;
                const response = await Session.api.getByArena(this.$store.getters.arena.id, `active=true&size=100&username=${this.foundEntity.username}`);
                const sessions = response.data.content;
                if (sessions) {
                    this.userSession = sessions.find(s => s.user_id === this.foundEntity.id)
                } else {
                    this.userSession = null;
                }
            } catch (e) {
                console.warn(`Arena Sessions API failed.`);
                console.log(e);
            } finally {
                this.loadingSessions = false;
            }
        },
    }
})
</script>

<style>

.nav-ham {
    position: relative;
    top: 6px;
    left: -6px;
    width: 40px;
    height: 45px;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.nav-ham span {
    display: block;
    position: absolute;
    background: #fff;
    height: 2px;
    left: 10px;
    width: 55%;
    border-radius: 9px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.nav-ham span:nth-child(1) {
    top: 15px;
}

.nav-ham span:nth-child(2) {
    top: 20px;
}

.nav-ham span:nth-child(3) {
    top: 25px;
}

.nav-ham.open span:nth-child(1) {
    width: 42%;
}

.nav-ham.open span:nth-child(3) {
    width: 42%;
}

.toolbar-title-width {
    width: 275px !important;
}

</style>