

import Vue from 'vue';

import convertCase from '@/mixins/convert-case';
import User from '@/models/users/User';

export default Vue.extend({
    props: ['text', 'item'],

    mixins: [
        convertCase,
    ],

    computed: {
        arenaName() {
            return this.$store.getters.arena ? `${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        },
    },

    methods: {
        createPurchase(user: User) {
            this.$emit('shop-button-clicked');
            if (this.$route.name !== 'navigationDrawer.shop.purchase') {
                this.$router.push({
                    name: 'navigationDrawer.shop.purchase',
                    params: {
                        arena: this.arenaName,
                        user: JSON.stringify(user)
                    }
                });
            } else {
                this.$router.replace({ path: '/temp' }) // Navigate to a temporary route
                .then(() => {
                    this.$router.replace({
                        name: 'navigationDrawer.shop.purchase',
                        params: {
                            arena: this.arenaName,
                            user: JSON.stringify(user)
                        }
                    });
                });
            }
        }
    }
});

